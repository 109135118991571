import { useMemo } from 'react'
import { useOptionsStore } from '../../store/useOptionsStore'
import styles from './Footer.module.css'
import classNames from 'classnames'

export const Footer = () => {
  const store = useOptionsStore()
  const city = useMemo(
    () => store.currentCity || store.currentLocation,
    [store.currentCity, store.currentLocation]
  )

  const handleClick = () => {
    store.toggleOptions()
  }

  return (
    <div className={styles.footer}>
      <p className={classNames(styles.city, 'flex-font24')}>{city.name}</p>
      <button className={styles.button} onClick={handleClick}>
        <img src='icons/options.svg' alt='options' width={32} />
      </button>
    </div>
  )
}
