import styles from './Wind.module.css'

type Props = {
  deg: number
  speed: number
  mini?: boolean
}

export const Wind = ({ deg, speed, mini = false }: Props) => {
  function getDirection(angle: number) {
    const directions = ['С', 'СВ', 'В', 'ЮВ', 'Ю', 'ЮЗ', 'З', 'СЗ']
    return directions[
      Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8
    ]
  }
  return (
    <div className={mini ? styles.windMini : styles.wind}>
      <p>{getDirection(deg)}</p>
      {/* <img src="icons/arrow.svg" alt="compass" style={{transform: `rotate(${props.deg}deg)`, height: props.arrowHeight}}/> */}
      <div>{speed.toFixed(0)} м/с</div>
    </div>
  )
}
