import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { City, Options, SearchCityData } from '../types'

type OptionsActions = {
  initLocation: (location: {
    name: string
    latitude: number
    longitude: number
  }) => void
  initLocationIsError: () => void
  deleteCity: (id: string) => void
  selectCity: (city: City) => void
  resetCity: () => void
  addCity: (city: SearchCityData) => void
  setForecast: (forecast: 'daily' | 'hourly') => void
  toggleOptions: () => void
}

const makeId = () => {
  let ID = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < 12; i++) {
    ID += characters.charAt(Math.floor(Math.random() * 36))
  }
  return ID
}

export const INIT_LOCATION = {
  id: makeId(),
  name: 'Москва',
  latitude: 55.751244,
  longitude: 37.618423,
}

const initState: Options = {
  citiesList: [],
  currentCity: null,
  currentLocation: INIT_LOCATION,
  lang: 'ru',
  units: 'metric',
  loading: true,
  error: false,
  activeForecast: 'daily',
  isOptionsOpen: false,
}

export const useOptionsStore = create(
  persist<Options & OptionsActions>(
    (set, get) => ({
      ...initState,
      initLocation(location) {
        set({
          currentLocation: {
            id: makeId(),
            name: location.name,
            latitude: location.latitude,
            longitude: location.longitude,
          },
          loading: false,
        })
      },
      initLocationIsError() {
        set({ currentLocation: INIT_LOCATION, loading: false })
      },
      deleteCity(id) {
        const prevList = get().citiesList
        const currentCity = get().currentCity
        set({
          citiesList: prevList.filter((item) => item.id !== id),
          currentCity:
            currentCity && currentCity.id === id ? null : currentCity,
        })
      },
      selectCity(city) {
        set({ currentCity: city })
      },
      resetCity() {
        set({ currentCity: null })
      },
      setForecast(forecast) {
        set({ activeForecast: forecast })
      },
      toggleOptions() {
        set({ isOptionsOpen: !get().isOptionsOpen })
      },
      addCity(city) {
        const { lat: latitude, lon: longitude, local_names } = city
        const name = local_names ? local_names.ru : city.name
        const id = makeId()
        const prevCitiesList = get().citiesList
        if (!prevCitiesList.some((item) => item.name === name)) {
          const newCity = { id, name, latitude, longitude }
          set({
            citiesList: [...prevCitiesList, newCity].sort((a, b) => {
              const nameA = a.name.toLowerCase()
              const nameB = b.name.toLowerCase()
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              return 0
            }),
            currentCity: newCity,
          })
        }
      },
    }),
    { name: 'weatherOptions' }
  )
)
