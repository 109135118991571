import { useOptionsStore } from '../../store/useOptionsStore'
import { CitiesListItem } from './CitiesListItem'
import classNames from 'classnames'
import styles from './CitiesList.module.css'

export function CitiesList() {
  const { citiesList, currentCity, currentLocation } = useOptionsStore(
    (store) => store
  )
  const selectCity = useOptionsStore((store) => store.selectCity)
  return (
    <ul className={classNames(styles.list, 'flex-font24')}>
      <li
        onClick={() => currentLocation && selectCity(currentLocation)}
        className={classNames(styles.location, {
          [styles.isSelected]: currentCity?.name === currentLocation?.name,
        })}
      >
        {currentLocation ? currentLocation.name : 'Не определен'}
      </li>
      {citiesList.map((city) => {
        return (
          <CitiesListItem
            key={city.id}
            isSelected={currentCity ? currentCity.id === city.id : false}
            city={city}
          />
        )
      })}
    </ul>
  )
}
