import { Main } from './components/Main'
import { DateView } from './components/DateView'
import useWeatherService from './hooks/useWeatherService'
import { Current } from './components/Current'
import { ForecastButtons } from './components/ForecastButtons'
import { WeatherList } from './components/WeatherList'
import { Footer } from './components/Footer'

function App() {
  const { weather, loading } = useWeatherService()
  return (
    <Main icon={weather?.current?.weather[0]?.icon} isLoading={loading}>
      <DateView utc={weather?.current.dt} />
      <Current currentWeather={weather?.current} />
      <ForecastButtons />
      <WeatherList weather={weather} />
      <Footer />
    </Main>
  )
}

export default App
