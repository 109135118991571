import axios from 'axios'
import { SearchCityData, WeatherData } from '../types'

const keyAPI = 'fcf8724495fdc0ffd44f1c13dde3b8df'

export const getWeather = async (
  lat: number,
  lon: number,
  currentLang: string,
  units: string
) => {
  const params = {
    lat,
    lon,
    exclude: 'minutely,alerts',
    appid: keyAPI,
    lang: currentLang,
    units,
  }
  const { data } = await axios.get<WeatherData>(
    'https://api.openweathermap.org/data/2.5/onecall',
    { params }
  )
  return data
}

export const getCity = async (cityName: string) => {
  const params = { q: cityName, limit: 5, appid: keyAPI }
  const { data } = await axios.get<SearchCityData[]>(
    'https://api.openweathermap.org/geo/1.0/direct',
    { params }
  )
  return data
}

export const getCityName = async (lat: number, lon: number) => {
  const params = {
    lat,
    lon,
    limit: 1,
    appid: keyAPI,
  }
  const { data } = await axios.get<SearchCityData[]>(
    'https://api.openweathermap.org/geo/1.0/reverse',
    { params }
  )
  return data[0]
}
