import { pwaInfo } from 'virtual:pwa-info';
import { useRegisterSW } from 'virtual:pwa-register/react';

// eslint-disable-next-line no-console
console.log(pwaInfo);

export const useSWPrompts = () => {
  // replaced dynamically
  const buildDate = '__DATE__';
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__';

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    offlineReady: [offlineReady, setOfflineReady],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      // eslint-disable-next-line no-console
      console.log(`Service Worker at: ${swUrl}`);
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        r &&
          setInterval(() => {
            // eslint-disable-next-line no-console
            console.log('Checking for sw update');
            r.update();
          }, 20000 /* 20s for testing purposes */);
      } else {
        // eslint-disable-next-line prefer-template,no-console
        console.log('SW Registered: ' + r);
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error);
    },
  });

  const closeNeedRefresh = () => {
    setNeedRefresh(false);
  };

  const closeOfflineReady = () => {
    setOfflineReady(false);
  };

  return {
    needRefresh,
    closeNeedRefresh,
    buildDate,
    updateServiceWorker,
    offlineReady,
    closeOfflineReady,
  };
};
