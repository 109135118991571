import { useMemo } from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import styles from './Main.module.css'
import classnames from 'classnames'
import { Options } from '../Options'
import { Spinner } from '../Spinner'

export const Main = ({
  icon,
  isLoading,
  children,
}: {
  icon?: string
  isLoading?: boolean
  children: React.ReactNode
}) => {
  const width = useWindowSize()

  const isMobile = useMemo(() => width < 500, [width])

  return (
    <div
      className={classnames(
        styles.main,
        isMobile ? styles.mobile : styles.desktop,
        styles[`is-${icon || 'default'}`]
      )}
    >
      {isLoading ? <Spinner /> : children}
      <Options isMobile={isMobile} />
    </div>
  )
}
