import React from 'react'
import { useOptionsStore } from '../../store/useOptionsStore'
import styles from './Options.module.css'
import { CitiesList } from '../CitiesList'
import { CitySearch } from '../CitySearch'
import classNames from 'classnames'
import { useSWPrompts } from '../../hooks/useSWPrompts'

export function Options({ isMobile = false }: { isMobile?: boolean }) {
  const store = useOptionsStore()

  const { needRefresh, closeNeedRefresh, updateServiceWorker } = useSWPrompts()

  const handleDownload = () => {
    updateServiceWorker().then(() => {
      closeNeedRefresh()
    })
  }

  const handleClick = () => {
    store.toggleOptions()
  }
  return (
    <div
      className={classNames(isMobile ? styles.mobile : styles.desktop, {
        [styles.open]: store.isOptionsOpen,
      })}
    >
      <CitySearch />
      <CitiesList />
      {needRefresh ? (
        <button className={styles.button} onClick={handleDownload}>
          Обновить приложение
        </button>
      ) : (
        <button className={styles.button} onClick={handleClick}>
          Назад
        </button>
      )}
    </div>
  )
}
