import classNames from 'classnames'
import { useOptionsStore } from '../../store/useOptionsStore'
import styles from './ForecastButtons.module.css'

export const ForecastButtons = () => {
  const options = useOptionsStore()
  return (
    <div className='row'>
      <button
        className={classNames(styles.button, 'flex-font18', {
          [styles.active]: options.activeForecast === 'hourly',
        })}
        onClick={() => options.setForecast('hourly')}
      >
        По часам
      </button>
      <button
        className={classNames(styles.button, 'flex-font18', {
          [styles.active]: options.activeForecast === 'daily',
        })}
        onClick={() => options.setForecast('daily')}
      >
        По дням
      </button>
    </div>
  )
}
