import classNames from 'classnames'
import { ucFirst } from '../../helpers'
import { CurrentWeather } from '../../types'
import { WeatherIcon } from '../WeatherIcon'
import styles from './Current.module.css'
import { Wind } from '../Wind'

export const Current = ({
  currentWeather = {
    temp: 0,
    feels_like: 0,
    weather: [],
    humidity: 0,
    wind_deg: 0,
    wind_speed: 0,
  },
}: {
  currentWeather?: Pick<
    CurrentWeather,
    'feels_like' | 'weather' | 'temp' | 'humidity' | 'wind_deg' | 'wind_speed'
  >
}) => {
  const { temp, feels_like, weather, humidity, wind_deg, wind_speed } =
    currentWeather
  const { id, icon, description } = weather[0] || {
    id: 803,
    icon: '04d',
    description: '',
  }

  return (
    <div className={styles.container}>
      <div className={styles.temperature}>
        {Math.round(temp)}°
        <WeatherIcon icon={id >= 800 ? icon : id.toString()} width='80px' />
      </div>
      <p>Ощущается как {Math.round(feels_like)}°</p>
      <p className='flex-font24'>{ucFirst(description)}</p>
      <div className={classNames(styles.wrapper, 'flex-font16')}>
        <div className={styles.conditions}>
          <img src='/icons/humidity.svg' alt='humidity' />
          <span> - </span>
          <div>{humidity}%</div>
        </div>
        <div className={styles.conditions}>
          <img src='icons/wind.svg' alt='wind' />
          <span> - </span>
          <Wind
            deg={wind_deg}
            speed={wind_speed}
            // arrowHeight="18px"
          />
        </div>
      </div>
    </div>
  )
}
