import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getCityName, getWeather } from '../services/WeatherService'
import { useOptionsStore } from '../store/useOptionsStore'

export default function useWeatherService() {
  const initLocation = useOptionsStore((store) => store.initLocation)
  const initLocationIsError = useOptionsStore(
    (store) => store.initLocationIsError
  )
  const {
    lang,
    units,
    currentCity,
    currentLocation,
    loading: stateLoading,
  } = useOptionsStore((store) => store)

  const city = currentCity || currentLocation

  const {
    data: weather = null,
    isLoading: isWeatherLoading,
    error: isWeatherError,
  } = useQuery({
    queryKey: ['weather', city],
    queryFn: async () => {
      const response = await getWeather(
        city.latitude,
        city.longitude,
        lang,
        units
      )
      return response
    },
    enabled: !!city,
  })

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const latitude = pos.coords.latitude
        const longitude = pos.coords.longitude
        const data = await getCityName(latitude, longitude)
        initLocation({
          name: data.local_names.ru || data.name || 'Неопределен',
          latitude: data.lat,
          longitude: data.lon,
        })
      },
      () => {
        initLocationIsError()
      }
    )
  }, [])

  const loading = stateLoading || isWeatherLoading
  const error = isWeatherError

  return {
    loading,
    weather,
    error,
  }
}
