import classNames from 'classnames'
import { useOptionsStore } from '../../store/useOptionsStore'
import { City } from '../../types'
import styles from './CitiesList.module.css'

interface Props {
  city: City
  isSelected: boolean
}
export function CitiesListItem({ city, isSelected }: Props) {
  const selectCity = useOptionsStore((store) => store.selectCity)
  const deleteCity = useOptionsStore((store) => store.deleteCity)

  const handleSelect = (city: City) => {
    selectCity(city)
  }

  const handleDelete = () => {
    deleteCity(city.id)
  }

  return (
    <li
      className={classNames(styles.item, { [styles.isSelected]: isSelected })}
    >
      <p onClick={() => handleSelect(city)}>{city.name}</p>
      <button className={styles.delete} onClick={handleDelete}>
        <img src='icons/delete.svg' alt='delete' />
      </button>
    </li>
  )
}
