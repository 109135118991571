import styles from './WeatherList.module.css'

import { getDateFromUtc } from '../../helpers'
import { DailyWeather, HourlyWeather } from '../../types'
import { WeatherIcon } from '../WeatherIcon'
import { Wind } from '../Wind'
import classNames from 'classnames'

export default function WeatherItem({
  forecastItem,
}: {
  forecastItem: HourlyWeather | DailyWeather
}) {
  const getWeekDay = (date: Date) => {
    let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    return days[date.getDay()]
  }

  const { dt, weather, temp, wind_deg, wind_speed } = forecastItem

  let title = ''
  let tempView = ''
  let weatherIcon = weather[0].id >= 800 ? weather[0].icon : weather[0].id

  if (typeof temp !== 'number') {
    title = `${getWeekDay(getDateFromUtc(dt))}, ${getDateFromUtc(dt).getDate()}`
    tempView = `${Math.round(temp.max)}/${Math.round(temp.min)}°`
  } else {
    title = getDateFromUtc(dt).toLocaleString('ru', {
      hour: 'numeric',
      minute: 'numeric',
    })
    tempView = `${Math.round(temp)}°`
  }
  return (
    <li className={classNames(styles.list, 'flex-font16')}>
      <p className='title'>{title}</p>
      <WeatherIcon icon={weatherIcon.toString()} width='70%' />
      <p>{tempView}</p>
      <Wind
        deg={wind_deg}
        speed={wind_speed}
        mini
        // arrowHeight="12px"
      />
    </li>
  )
}
