import { useEffect, useMemo, useRef } from 'react'
import WeatherItem from './WeatherListItem'
import { WeatherData } from '../../types'
import styles from './WeatherList.module.css'
import { useOptionsStore } from '../../store/useOptionsStore'
import { setHourly } from '../../helpers'

export const WeatherList = ({ weather }: { weather?: WeatherData | null }) => {
  const componentRef = useRef<HTMLUListElement>(null)
  const activeForecast = useOptionsStore((store) => store.activeForecast)
  const forecast = useMemo(() => {
    if (weather) {
      return activeForecast === 'hourly'
        ? setHourly(weather[activeForecast])
        : weather[activeForecast]
    }
    return []
  }, [weather, activeForecast])

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollLeft = 0
    }
  }, [activeForecast])

  return (
    <ul className={styles.scroll} ref={componentRef}>
      {forecast.map((weather) => (
        <WeatherItem key={weather.dt} forecastItem={weather} />
      ))}
    </ul>
  )
}
