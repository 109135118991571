import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { getCity } from '../../services/WeatherService'
import { useOptionsStore } from '../../store/useOptionsStore'
import { SearchCityData } from '../../types'

import styles from './CitySearch.module.css'
import { CitySearchResult } from './CitySearchResult'
import { useDebounce } from '../../hooks/useDebounce'

export const CitySearch = () => {
  const [value, setValue] = useState('')
  const debounceValue = useDebounce(value, 700)
  const [result, setResult] = useState<SearchCityData[] | null>(null)
  const addCity = useOptionsStore((store) => store.addCity)

  const { mutate: city, isError: error } = useMutation({
    mutationFn: getCity,
    onSuccess(data) {
      if (data.length > 0) {
        setResult(data)
      } else {
        setResult([])
      }
    },
    onError() {
      setValue('')
    },
  })

  const onClick = (city: SearchCityData) => {
    addCity(city)
    setValue('')
    setResult(null)
  }

  const handleClear = () => {
    setValue('')
    setResult(null)
  }

  useEffect(() => {
    if (debounceValue.length >= 2) {
      city(debounceValue)
    }
  }, [debounceValue])

  return (
    <div className={styles.search}>
      <div className={styles.container}>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type='text'
          placeholder='Поиск города'
          className={styles.text}
        />
        {value && (
          <button className={styles.clearButton} onClick={handleClear}>
            <img src='/icons/delete.svg' alt='add' />
          </button>
        )}
        <ul className={styles.dropdown}>
          {result ? (
            result.length > 0 ? (
              result.map((city, id) => (
                <CitySearchResult
                  key={id}
                  city={city}
                  onClick={() => onClick(city)}
                />
              ))
            ) : (
              <li className={styles.item}>Ничего не найдено</li>
            )
          ) : null}
        </ul>
      </div>
    </div>
  )
}
