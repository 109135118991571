import { getDateFromUtc, ucFirst } from '../../helpers'
import { useOptionsStore } from '../../store/useOptionsStore'

export const DateView = ({ utc }: { utc?: number }) => {
  const lang = useOptionsStore((store) => store.lang)
  const date = utc ? getDateFromUtc(utc) : new Date()
  const day = date.toLocaleString(`${lang}`, {
    weekday: 'long',
  })
  const stringDate = date.toLocaleString(`${lang}`, {
    day: 'numeric',
    month: 'long',
  })
  return (
    <div className='row'>
      <p className='flex-font24'>{ucFirst(day)}</p>
      <p className='flex-font24'>{stringDate}</p>
    </div>
  )
}
