import React from 'react'
import { SearchCityData } from '../../types'
import styles from './CitySearch.module.css'

interface Props {
  city: SearchCityData
  onClick: () => void
}

export const CitySearchResult = ({ city, onClick }: Props) => {
  const name = city.local_names ? city.local_names.ru : city.name
  return (
    name && (
      <li className={styles.item} onClick={onClick}>
        <img src='icons/add.svg' alt='add' />
        <p>{name}</p>
      </li>
    )
  )
}
