import { HourlyWeather } from '../types'

export const getDateFromUtc = (date: number) => {
  return new Date(date * 1000)
}

export const ucFirst = (str: string) => {
  if (!str) {
    return str
  }
  return str[0].toUpperCase() + str.slice(1)
}

export const setHourly = (arr: HourlyWeather[]) => {
  const newArr = []
  // погода на ближайшие 24 часа
  for (let i = 0; i < 24; i = i + 2) {
    newArr.push(arr[i])
  }
  return newArr
}
